function Macheo(datos) {
    this.datos = datos;
    this.body = $('body');
}
Macheo.prototype.comprobar = function() {
    this.body.trigger('verificando-usuario');
    this.body.trigger('enviando-formulario');

    var $this = this
    $.post('/apilocal/usuario/validar',
        this.datos,
        function() {},
        'json'
    )
        .done(function(respuesta) {
            if(respuesta.confirmarClubPremium === true){
                $this.body.trigger('mostrar-club-premium');
            }
            if(respuesta.pedirClave===true) {
                $('body').trigger('usuario-nuevo')
                $this.body.trigger('usuario-verificado');
                $this.body.trigger('formulario-enviado');
            }
            else {
                $('body').trigger('usuario-macheado')
                $this.body.trigger('usuario-verificado');
                $this.body.trigger('formulario-enviado');
            }
        })
        .fail(function(respuesta) {
            $('body').trigger('usuario-macheado')
            $this.body.trigger('usuario-verificado');
            $this.body.trigger('formulario-enviado');
        });
}

export default Macheo;
