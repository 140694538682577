function Telefono(telefono) {
    this.telefonoOriginal = telefono;
    this.telefono = telefono;
    this.telefonoEnFormatoNumerico = 0;

    this.quitarEspacios();
    this.quitarSimboloMas();
    this.convertirEnNumero();
}
Telefono.prototype.validar = function() {
    if (this.telefono.length<9) {
        return false;
    }
    return this.telefono === this.telefonoEnFormatoNumerico.toString();
}
Telefono.prototype.quitarEspacios = function() {
    this.telefono = this.telefono.replace(/\s/g, '');
}
Telefono.prototype.quitarSimboloMas = function() {
    this.telefono = this.telefono.replace(/\+/g, '');
}
Telefono.prototype.convertirEnNumero = function() {
    this.telefonoEnFormatoNumerico = parseInt(this.telefono, 10);
}

export default Telefono;
