import cargarToken from "./token";
import Email from "./email";
import funciones from "./funciones";
import Fecha from "./fecha";
import Contrasena from "./contrasena";
import Macheo from "./macheo";
import Mensajes from "./mensajes";
import Telefono from "./telefono";
import Usuario from "./usuario";

function FormularioLogin() {
    this.campos = {};
}
FormularioLogin.prototype.configurar = function(domFormulario, tieneCampoOculto, configuracion, gestorMensajes) {
    this.email = domFormulario.find('[name ="email"]');
    this.campos.password = domFormulario.find('[name ="password"]');
    this.tieneCampoOculto = tieneCampoOculto;
    this.tieneEmailValido = false;
    this.errores = gestorMensajes
    this.hayQueEnviar = false;
    this.body = $('body');

    var $this = this;

    $(this.email).change(function(e) {$this.emailCambiado(e)})
    $(this.email).keyup(function(e) {$this.emailCambiado(e)})
    this.body.on('email-existe', function(e) { $this.emailExiste()})
    this.body.on('email-no-existe', function(e) { $this.emailNoExiste()})

    this.emailCambiado();

    var enlaceRecordarContrasena = $('[data-enlace="recordar-contrasena"]');
    var enlaceActual = enlaceRecordarContrasena.attr('href')
    enlaceRecordarContrasena.attr('href', enlaceActual + '?volver=' + window.location.pathname)
}

FormularioLogin.prototype.emailExiste = function (e) {
    this.tieneEmailValido = true;
    if (this.hayQueEnviar && !this.tieneCampoOculto) {
        this.validarFormulario();
        if (this.errores.hayErrores()) {
            this.errores.mostrar();
            return false;
        }
        cargarToken().then(() => {
            console.log("Recargando token");
            this.enviarFormulario();
        });
    }
    if (this.tieneCampoOculto) {

    }
}

FormularioLogin.prototype.emailNoExiste = function () {
    this.tieneEmailValido = false;
}

FormularioLogin.prototype.emailCambiado = function() {
    $('body').trigger('limpiar-mensajes');
    var email = new Email(this.email.val());
    if(email.validar()) {
        var usuario = new Usuario(email.email, this._token);
        usuario.comprobarSiExiste();
    }
}

FormularioLogin.prototype.eventoFormularioEnviado = function(evento) {
    evento.preventDefault();

    this.hayQueEnviar = true;

    this.errores.limpiar();

    if (this.validarEmail()) {
        if (this.tieneCampoOculto) {
            this.comprobarSiExisteUsuario()
            return false;
        }
    }

    this.validarFormulario();

    if (this.errores.hayErrores()) {
        this.errores.mostrar();
        return false;
    }

    if (!this.tieneEmailValido) {
        return false;
    }

    this.enviarFormulario();

}

FormularioLogin.prototype.comprobarSiExisteUsuario = function () {
    var datos = {
        _token: window.token,
        email: this.email.val()
    }
    var $this = this;

    this.body.trigger('enviando-formulario');
    $('[data-link-action="sign-in"]').removeClass('disabled');
    $.post('/apilocal/email/validar', datos)
        .done(function (respuesta) {

            $this.body.trigger('formulario-enviado');
            if (respuesta.existe === true) {
                $('[data-mostrar-si-hay-login="si"]').show();
                $this.tieneCampoOculto = false;

            } else {
                console.log("[comprobarSiExisteUsuario]")
                if ($this.tieneCampoOculto && $this.hayQueEnviar) {
                    console.log("[comprobarSiExisteUsuario] Campo oculto y hay que enviar")
                    $('body').trigger('mostrar-formulario', {formulario: 'registro'})
                }
                else {
                    console.log("[comprobarSiExisteUsuario] else")
                    $('[data-mostrar-si-no-existe-usuario="si"]').show();
                }
            }
        });
}

FormularioLogin.prototype.validarEmail = function() {
    if (!this.email.val()) {
        return false;
    }

    return !(this.email.val() && !funciones.esEmail(this.email.val()));
}

FormularioLogin.prototype.validarFormulario = function() {
    if (!window.token) {
        this.errores.nuevo("Error interno, vuelva a recargar la página");
    }

    if (!this.validarEmail()) {
        this.errores.nuevo("El email no es válido");
    }

    if (this.validarEmail() && !this.tieneEmailValido) {
        //this.errores.nuevo('No te encontramos como cliente en ese email <a href="#" data-click="cambiar-formulario" data-formulario="registro"data-cy="formulario-modal-boton-ver-registro">¿Quieres registrarte?</a>')
    }

    var password = new Contrasena(this.campos.password.val());
    if (!password.validar()) {
        this.errores.nuevo(password.errorValidacion());
    }
}

FormularioLogin.prototype.enviarFormulario = function() {
    var datos = {
        back: window.location.href,
        _token: window.token,
        email: this.email.val(),
        password: this.campos.password.val()
    };
    this.body.trigger('enviando-formulario');

    var $this = this;
    $.post(
        '/login',
        datos,
        function() {},
        'json'
    )
        .done(function(respuesta) {
            // rrApi.setEmail(respuesta.datosUsuario.Email);
            var back = respuesta.back || '/areacliente';
            window.location.href = respuesta.url + '?back=' + back;
        })
        .fail(function(respuesta) {
            $this.body.trigger('formulario-enviado');
            if (typeof respuesta.responseJSON==='undefined') { // Probablemente sea una redirección. Desloguear y volver a loguear
                $.get('/tienda/?mylogout=&url=/')
                    .done(function () {
                        cargarToken().then(() => {
                            $this.enviarFormulario()
                        })
                    })

                return
            }
            if (typeof respuesta.responseJSON.errors!=='undefined') {
                $.each(respuesta.responseJSON.errors, function (indice, error) {
                    $this.errores.nuevo(error);
                });
            }
            else {
                $.each(respuesta.responseJSON, function (indice, error) {
                    $this.errores.nuevo(error);
                });
            }

            $this.errores.mostrar();
        });
}

FormularioLogin.prototype.ocultarPassword = function() {
    this.tieneCampoOculto = true;
    this.tieneEmailValido = false;
    $('[data-mostrar-si-hay-login="si"]').hide();
    $('body').trigger('limpiar-mensajes');
}

export default FormularioLogin;
