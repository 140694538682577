import funciones from "./funciones";

function Email(email) {
    this.email = email;
}
Email.prototype.validar = function () {

    if (!this.email) {
        return false;
    }
    return !(this.email && !funciones.esEmail(this.email));
}
Email.prototype.esEmail = function() {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(this.email);
}

export default Email;
