class Puntos {

    constructor() {
        this.domContenedorPuntos = $('[data-contenedor="puntos"]')
        this.domPuntos = $('[data-seccion="puntos"]')
    }

    mostrarPuntos(importe) {
        if(!this.esNecesarioCargarPuntos()) {
            return
        }

        $.get('/apilocal/importe-a-puntos/' + importe)
            .done((respuesta) => {
                let puntos = parseInt(respuesta)
                this.domPuntos.html(parseInt(puntos))
                if (puntos>0) {
                    this.domContenedorPuntos.show()
                }
            })
    }

    esNecesarioCargarPuntos() {
        return this.domPuntos
    }

}

function mostrarPuntos() {
    let puntos = new Puntos

    let precioProducto = ($("meta[property='product:price:amount']").attr("content"))
    let importeTotalCarrito = $('.cart-total').find('.value').html()//prestashop.cart.totals.total_including_tax.amount
    if (importeTotalCarrito) {
        importeTotalCarrito = importeTotalCarrito.replace('&nbsp;€', '')
        importeTotalCarrito = importeTotalCarrito.replace(',', '.')
    }
    let importe = 0

    if (precioProducto) {
        importe = precioProducto
    }
    else {
        importe = importeTotalCarrito
    }

    puntos.mostrarPuntos(importe)
}

export default mostrarPuntos
