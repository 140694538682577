class Secciones {
    cargar() {
        return new Promise(((resolve, reject) => {
            $.get('/tienda/secciones.json')
                .done(respuesta => {
                    resolve(respuesta)
                })
                .fail(() => {
                    reject('Falta el fichero de secciones')
                })
        }))

    }
}

export default Secciones;
