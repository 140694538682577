class Modales {

    constructor() {
        const queryString = window.location.search;
        
        this.hayQueAbrirModalLogin = queryString.indexOf('modal=login');
        this.hayQueAbrirModalRegistro = queryString.indexOf('modal=registro');
        this.hayQueAbrirModalPasswordEmail = queryString.indexOf('modal=password-email');
        this.hayQueAbrirModalPremium = queryString.indexOf('modal=club-premium');
        this.hayQueAbrirModalFelicidades = queryString.indexOf('modal=felicidades');
        if (this.hayQueAbrirModalPasswordEmail===-1) {
            this.hayQueAbrirModalPassword = queryString.indexOf('modal=password');
        }
        else {
            this.hayQueAbrirModalPassword = -1;
        }

        this.botonActivadorModal = $('[data-click="abrir-modal-login"]');
    }

    abrirModalSeleccionada() {
        console.log(this.hayQueAbrirModalLogin)
        if (this.hayQueAbrirModalLogin!==-1) {
            console.log("hayQueAbrirModalLogin")
            this.botonActivadorModal.trigger('click');
            $('body').trigger('mostrar-formulario', { formulario: 'login' });
        }
        if (this.hayQueAbrirModalRegistro!==-1) {
            this.botonActivadorModal.trigger('click');
            $('body').trigger('mostrar-formulario', { formulario: 'registro' });
        }
        if (this.hayQueAbrirModalPassword!==-1) {
            this.botonActivadorModal.trigger('click');
            $('body').trigger('mostrar-formulario', { formulario: 'password' });
        }
        if (this.hayQueAbrirModalPremium!==-1) {
            $('#boton-abrir-modal-club-premium').trigger('click')
            $('body').trigger('mostrar-formulario', { formulario: 'club-premium' });
        }
        if (this.hayQueAbrirModalFelicidades!==-1) {
            $('#boton-abrir-modal-club-felicidades').trigger('click')
            $('body').trigger('mostrar-formulario', { formulario: 'felicidades' });
        }
        if (this.hayQueAbrirModalPasswordEmail!==-1) {
            //this.botonActivadorModal.trigger('click');
            //$('#modal-boton-cambiar-a-formulario-password-email').trigger('click');
        }

        if (this.hayQueAbrirModalPassword!==-1) {
            this.botonActivadorModal.trigger('click');
            $('body').trigger('mostrar-formulario', { formulario: 'password' });
        }
    }
}

export default Modales;
