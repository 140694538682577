import cargarToken from "./token";
import Email from "./email";
import esEmail from "./funciones";
import Fecha from "./fecha";
import Contrasena from "./contrasena";
import Macheo from "./macheo";
import Mensajes from "./mensajes";
import Telefono from "./telefono";
import Usuario from "./usuario";

function FormularioRegistro() {
    this.campos = {}
    this.validador = null
}

FormularioRegistro.prototype.configurar = function(domFormulario, campos, validador, gestorMensajes) {
    this.campos = campos;
    this.validador = validador;
    this.emailYaExiste = false;
    this.usuarioComprobado = false;
    this.seHaBuscadoElUsuario = false;
    this.mostrarContrasena = false;
    this.errores = gestorMensajes;
    this.domFormulario = domFormulario;
    this.hayQueEnviarFormulario = false;

    this.email = domFormulario.find('[name="email"]');
    this.password = domFormulario.find('[name="password"]')

    this.configurarEventos();
    this.emailCambiado();
}
FormularioRegistro.prototype.configurarEventos = function() {
    var $this = this;
    $(this.email).change(function(e) {$this.emailCambiado(e)})
    $(this.email).keyup(function(e) {$this.emailCambiado(e)})
    this.body = $('body');
    this.body.on('email-existe', function(e) { $this.emailExiste()})
    this.body.on('email-no-existe', function(e) { $this.emailNoExiste()})
    this.body.on('usuario-nuevo', function(e) { $this.usuarioNuevo()})
    this.body.on('usuario-macheado', function(e) { $this.usuarioMacheado()})
    this.body.on('datos-cambiados', function(e) { $this.datosCambiados()})
}
FormularioRegistro.prototype.datosCambiados = function() {
    this.errores.limpiar();
    this.usuarioComprobado = false;
    this.hayQueEnviarFormulario = false;
}
FormularioRegistro.prototype.emailCambiado = function() {
    $('body').trigger('limpiar-mensajes');

    var email = new Email(this.email.val());
    if(email.validar()) {
        var usuario = new Usuario(email.email, this._token);
        usuario.comprobarSiExiste();
    }
}

FormularioRegistro.prototype.eventoFormularioEnviado = function(evento) {
    evento.preventDefault();
    this.hayQueEnviarFormulario = true;

    this.errores.limpiar();

    this.validarFormulario();

    if (this.errores.hayErrores()) {
        this.errores.mostrar();
        $('body').trigger('reactivar-botones');
        return false;
    }

    if (this.usuarioComprobado) {
        this.enviarFormulario();
    }
    else {
        this.comprobarSiElUsuarioExiste();
    }
}

FormularioRegistro.prototype.comprobarSiElUsuarioExiste = function() {
    var macheo = new Macheo(this.recopilarDatos());
    var resultadoMacheo = macheo.comprobar();

    if (resultadoMacheo===macheo.sePuedeRegistrar) {
    }
}

FormularioRegistro.prototype.validarFormulario = function() {
    this.errores = this.validador.validar()

    if (this.mostrarContrasena && !this.domFormulario.find('[name="password"]').val()) {
        this.errores.nuevo('Por favor, escoge una contraseña.');
    }

    if (this.emailYaExiste) {
        this.errores.nuevo('Ese email ya está en uso <a href="#" data-click="cambiar-formulario" data-nombre-formulario="login">¿quieres loguearte?</a>.')
    }

    var telefono = new Telefono(this.domFormulario.find('[name="telefono"]').val());
    if (!telefono.validar()) {
        this.errores.nuevo('El teléfono debe tener al menos 9 dígitos y solo puede contener espacios y el símbolo "+".');
    }

    var password = new Contrasena(this.password.val());
    if (this.mostrarContrasena && !password.validar()) {
        this.errores.nuevo(password.errorValidacion());
    }
}

FormularioRegistro.prototype.recopilarDatos = function() {
    let back = window.location.href,
        backDelFormulario = this.domFormulario.find('[name="url-back"]')

    if (typeof backDelFormulario!=='undefined' && backDelFormulario.val()) {
        back = backDelFormulario.val();
    }

    var datos = {
        back: back,
    };

    var $this = this;

    $.each(this.campos, function(index, campo) {
        var primerCaracterCampo = campo.substring(0, 1);
        if (primerCaracterCampo!=='[') {
            campo = '#' + campo;
        }
        datos[index] = $this.domFormulario.find(campo).val();
    });
    if(this.domFormulario.find('#aceptaPrivacidad').is(':checked')) {
        datos.aceptaPrivacidad = 'on'
    }
    if(this.domFormulario.find('#aceptaComunicacionesElectronicas').is(':checked')) {
        datos.aceptaComunicacionesElectronicas = 'on'
    }
    if(this.domFormulario.find('#aceptaComunicacionesPostales').is(':checked')) {
        datos.aceptaComunicacionesPostales = 'on'
    }
    datos.email = this.email.val();
    datos._token = window.token;

    if (this.password.val()) {
        datos.password = this.password.val();
    }

    return datos;
}

FormularioRegistro.prototype.enviarFormulario = function() {
    var datos = this.recopilarDatos();
    var $this = this;

    this.body.trigger('enviando-formulario');

    $.post(
        '/alta-cliente',
        datos,
        function() {},
        'json'
    )
        .done(function(respuesta) {
            $this.body.trigger('formulario-enviado');
            if(!document.getElementById('aceptaComunicacionesElectronicas').checked) {
                if ((respuesta.url.match(/\?/g) || []).length > 1) {
                    respuesta.url += "&";
                } else {
                    respuesta.url += "?";
                }
                respuesta.url += "modal=club-premium";           
            } 
            window.location.href = respuesta.url;
            
        })
        .fail(function(respuesta) {
            $this.body.trigger('formulario-enviado');
            $.each(respuesta.responseJSON, function(indice, error) {
                $this.errores.nuevo(error);
            });

            $this.errores.mostrar();
        });
}

FormularioRegistro.prototype.emailExiste = function() {
    this.emailYaExiste = true;
}
FormularioRegistro.prototype.emailNoExiste = function() {
    this.emailYaExiste = false;
}
FormularioRegistro.prototype.usuarioNuevo = function() {
    this.usuarioComprobado = true;
    this.mostrarContrasena = true;
}
FormularioRegistro.prototype.usuarioMacheado = function() {
    this.usuarioComprobado = true;
    this.mostrarContrasena = false;
    if (this.hayQueEnviarFormulario) {
        this.enviarFormulario();
    }
}

FormularioRegistro.prototype.redirigirA = function(url) {
    console.log(this);
    rrApi.setEmail(this.email.val());
    //window.location.href = url;
}

export default FormularioRegistro;


