function Usuario(email) {
    this.email = email
    this.body = $('body');
}
Usuario.prototype.comprobarSiExiste = function() {
    var datos = {
        _token: window.token,
        email: this.email
    }
    this.body.trigger('verificando-email');
    this.body.trigger('enviando-formulario');
    var $this = this;

    $.post('/apilocal/email/validar', datos)
        .done(function (respuesta) {
            if (respuesta.existe === true) {
                $this.body.trigger('email-existe');
            } else {
                $this.body.trigger('email-no-existe');
            }
            $this.body.trigger('email-verificado');
            $this.body.trigger('formulario-enviado');

        });
}

export default Usuario;
