class LeerMas {

    constructor() {
        this.body = $('body')
        this.asociarDom()
        this.configurarEventos()
    }

    asociarDom() {
        this.botonLeerMas = $('[data-click="leer-mas"]')
        this.botonLeerMenos = $('[data-click="leer-menos"]')
        this.containerResumen = $('#js-product-list-bottom')
        this.containerResto = $('#texto-abajo-leer-mas')
    }

    configurarEventos() {
        this.body.delegate('[data-click="leer-mas"]', 'click', (e) => this.eventoLeerMas(e))
        this.body.delegate('[data-click="leer-menos"]', 'click', (e) => this.eventoLeerMenos(e))
    }

    mostrar(seccion) {
        seccion.removeClass('hidden')
        seccion.addClass('d-block')
    }

    ocultar(seccion) {
        seccion.removeClass('d-block')
        seccion.addClass('hidden')
    }

    eventoLeerMas() {
        this.asociarDom()
        this.mostrar(this.containerResto)
        this.ocultar(this.botonLeerMas)
        this.mostrar(this.botonLeerMenos)
    }

    eventoLeerMenos() {
        this.asociarDom()
        this.ocultar(this.containerResto)
        this.mostrar(this.botonLeerMas)
        this.ocultar(this.botonLeerMenos)

        $('html, body').animate({
            scrollTop: this.containerResumen.offset().top - 100
        }, 1000);

    }
}

export default LeerMas
