import configurarLoginRegistro from './login-registro-01'
import mostrarPuntos from "./puntos";
import Secciones from "./secciones";
import LeerMas from "./front/leer-mas";
import configurarSlider from "./front/slider-home";
import cargarToken from "./token";
import lazyLoading from "./lazy-loading";
import carrito from "./carrito";

function ocultarRetailRocketSiHayFiltros() {
    let elementoScroll = $("#js-active-search-filters");
    if(elementoScroll.length == 0) {
        return;
    }
    if (!elementoScroll.hasClass('hide')) {
        const bloqueRetailRocket = $('[data-retailrocket-markup-block]');

        bloqueRetailRocket.hide();
        bloqueRetailRocket.attr('data-display', 0);
    }

}

function recargarBloqueRetailRocket() {
    $('#div_category_page_popular_1').removeAttr('initialized');
    delete retailrocket.modules.duplicates;
    retailrocket.markup.render();
}
function arreglarUrlOrden(currentUrl, item) {
    var currentUrlObject = new URL(window.location.href);
    var url = new URL(item.attr('href'));
    const order = url.searchParams.get('order')
    if (order) {
        currentUrlObject.searchParams.set('order', order)
    }
    item.attr('href', currentUrlObject.href)
}

function arreglarUrlResto(currentUrl, item) {
    if (item.hasClass('js-filtro-ordenar')) {
        return;
    }
    var currentUrlObject = new URL(window.location.href);
    var url = new URL(item.attr('href'));
    const order = currentUrlObject.searchParams.get('order')
    if (order) {
        url.searchParams.set('order', order)
    }
    item.attr('href', url.href)
}

function arreglarUrlFiltros() {
    $(".js-filtro-ordenar").each(function (index, item) {
        arreglarUrlOrden(window.location, $(item))
    })
    $(".js-search-link").each(function (index, item) {
        arreglarUrlResto(window.location, $(item))
    })
}

window.addEventListener('load', function() {
    arreglarUrlFiltros();
})

let lastUrl = location.href;
new MutationObserver(() => {
    const url = location.href;
    if (url !== lastUrl) {
        lastUrl = url;
        onUrlChange();
    }
}).observe(document, {subtree: true, childList: true});

function onUrlChange() {
    arreglarUrlFiltros();
    setTimeout(function() {
        arreglarUrlFiltros();
    }, 200);
}

function ejecutarCuandoEsteObjetoPrestashop() {
    try {
        prestashop.on('updatedCart', (e) => {
            mostrarPuntos();
        })

        if (prestashop.page.page_name==='checkout') {
            const metodosEnvioIds = {
                recogida: 4,
                envio: 44,
            }
            console.log("Checkout")
            $.get('/tienda/module/medicalopticarecogidatienda/GetCustomerAddress?action=action_name&ajax=true')
                .then(address => {
                    const addressHtml = `<span class="resumen--title">DIRECCIÓN ENVÍO</span>${address.name} ${address.lastname}<br/>${address.address}, ${address.postcode}, ${address.city}`
                    $('#resumen--direccion').html(addressHtml)
                    const inputRecogida = $(`#delivery_option_${metodosEnvioIds.recogida}`)
                    const precioRecogida = $(`#carrier_price_${metodosEnvioIds.recogida}`).data('price')
                    const inputEnvio = $(`#delivery_option_${metodosEnvioIds.envio}`)
                    const precioEnvio = $(`#carrier_price_${metodosEnvioIds.envio}`).data('price')
                    const spanRecogida = $(`#carrier_price_${metodosEnvioIds.envio}`)
                    let divPrecioEnvio = $($("#cart-subtotal-shipping").children()[1])
                    let divPrecioTotal = $($(".cart-total").children()[1])
                    let precioTransporte


                    if (address.isStore) { // Envío tienda
                        inputRecogida.prop('checked', true);
                        inputEnvio.prop('checked', false);
                        inputEnvio.parent().parent().hide();
                        divPrecioEnvio.html('GRATIS');
                        precioTransporte = precioRecogida;
                        $(`#delivery_span_${metodosEnvioIds.recogida}`).css('display', 'block');
                        $(".transporte").css('display', 'block');
                    }
                    else { // Envío domicilio
                        inputEnvio.prop('checked', true);
                        inputRecogida.prop('checked', false);
                        inputRecogida.parent().parent().hide();
                        divPrecioEnvio.html((''+precioEnvio.toFixed(2)).replace('.', ',') + ' €');
                        precioTransporte = precioEnvio;
                        $(`#delivery_span_${metodosEnvioIds.envio}`).css('display', 'block');
                        $(".transporte").css('display', 'block');
                    }
                    if(prestashop.page.page_name == 'cart') {
                        if (precioTransporte) {
                            const precioTotal = (prestashop.cart.total_including_tax + precioEnvio).toFixed(2)
                            divPrecioTotal.html(precioTotal + " &euro;")
                        }
                        else {
                            const precioTotal = (prestashop.cart.total_including_tax).toFixed(2)
                            divPrecioTotal.html(precioTotal + " &euro;")
                        }
                    }

                })
        }

        prestashop.on('updateProductList', (data) => {
            console.log("Evento updateProductList");
            let elementoScroll = $("#js-active-search-filters");

            const bloqueRetailRocket = $('[data-retailrocket-markup-block]');

            // Comprobamos si hay filtros activos
            if (data.rendered_active_filters.search('hidden-xs-up')>0) { // Si los filtros están ocultos
                bloqueRetailRocket.show();
                bloqueRetailRocket.attr('data-display', 1);
                recargarBloqueRetailRocket();
            }
            else {
                bloqueRetailRocket.hide();
                bloqueRetailRocket.attr('data-display', 0);
            }
            if (!elementoScroll.is(":visible")) {
                elementoScroll = $("#js-product-list-top");
            }


            $("html").animate(
                {
                    scrollTop: elementoScroll.offset().top - 100
                },
                800 //speed
            );

            setTimeout(function() {
                lazyLoading();
                console.log("Aplicar el lazy loading")
            }, 100)

            setTimeout(function() {
                lazyLoading();
                console.log("Reaplicar el lazy loading")
            }, 6000)

            //arreglarUrlFiltros();

        });

    } catch (e) {
        window.setTimeout(ejecutarCuandoEsteObjetoPrestashop, 20);
    }
}

function mostrarMensajeRecalculandoEnCesta() {
    $('.cart-total').find('.value').html('Recalculando..');
}

function mostrarElMensajeRecalculandoAlCambiarArticulosEnLaCesta() {
    $(document).on('change', '.js-cart-line-product-quantity', function() {
        mostrarMensajeRecalculandoEnCesta();
    });
    $(document).on('click', '.remove-from-cart', function() {
        mostrarMensajeRecalculandoEnCesta();
    });
}

function runMainScript() {

    if (window.jQuery) {
        ocultarRetailRocketSiHayFiltros();

        mostrarElMensajeRecalculandoAlCambiarArticulosEnLaCesta();

        scrollAlElementoActualDelCheckout();

        ejecutarCuandoEsteObjetoPrestashop()

        carrito.setup();

        configurarSlider()

        let cargadorSecciones = new Secciones()
        cargadorSecciones.cargar()
            .then((secciones) => {
                if (secciones.puntos) {
                    mostrarPuntos();
                }
            })
            .catch((mensaje) => {
                console.log(mensaje)
            })

        cargarToken()
            .then(() => {
                configurarLoginRegistro();
            });

        let leerMas = new LeerMas()

    } else {
        // Esperar 50 milisegundos si no está disponible jQuery:
        window.setTimeout(runMainScript, 20);
    }

    lazyLoading();
}

function scrollAlElementoActualDelCheckout() {
    var elementoCheckoutActual = $('.js-current-step'),
        headerHeight = $("#cabecera").height() + 5; // Get fixed header height

    if (!elementoCheckoutActual) {
        return false;
    }

    if (elementoCheckoutActual.length) {
        $('html,body').animate({
            scrollTop: elementoCheckoutActual.offset().top - headerHeight
        }, 500);
        return false;
    }

}

export default runMainScript;
