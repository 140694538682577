import Email from "./email";
import FormularioPasswordEmail from "./formulario-password-email";
import FormularioLogin from "./formulario-login";
import FormularioRegistro from "./formulario-registro";
import Fecha from "./fecha";
import Contrasena from "./contrasena";
import Macheo from "./macheo";
import Mensajes from "./mensajes";
import Telefono from "./telefono";
import Usuario from "./usuario";
import ValidadorRegistro from "./validador-registro";
import FormularioPasswordReset from "./formulario-password-reset";
import Modales from "./modales";

var configuracionFormularioLogin = {
    contenedorErrores: 'formulario-registro-errores',
    campoFecha: 'fecha-nacimiento',
    camposObligatorios: [{
            nombre: 'email',
            label: 'Email',
            tipo: 'email',
        },
        {
            nombre: 'password',
            label: 'Contraseña',
            tipo: 'texto',
        },
    ]
};

var configuracionFormularioRegistro = {
    idFormulario: 'mi-formulario-registro',
    contenedorErrores: 'formulario-registro-errores',
    campoFecha: 'fecha-nacimiento',
    camposObligatorios: [{
            nombre: 'nombre',
            label: 'Nombre',
            tipo: 'texto',
        },
        {
            nombre: 'apellido1',
            label: 'Primer apellido',
            tipo: 'texto',
        },
        {
            nombre: 'apellido2',
            label: 'Segundo apellido',
            tipo: 'texto',
        },
        {
            nombre: 'sexo',
            label: 'Género',
            tipo: 'select',
        },
        {
            nombre: 'dia',
            label: 'Fecha nacimiento: día',
            tipo: 'select',
        },
        {
            nombre: 'mes',
            label: 'Fecha nacimiento: mes',
            tipo: 'select',
        },
        {
            nombre: 'agno',
            label: 'Fecha nacimiento: año',
            tipo: 'select',
        },
        {
            nombre: 'telefono',
            label: 'Teléfono',
            tipo: 'texto',
        },
        {
            nombre: 'aceptaPrivacidad',
            label: 'Aviso legal',
            tipo: 'checkbox',
        },
    ],
    campos: {
        nombre: 'nombre',
        apellido1: 'primerApellido',
        apellido2: 'segundoApellido',
        email: 'email',
        telefono: 'telefono',
        sexo: 'sexo',
        fechaNacimiento: 'fecha-nacimiento',
        aceptaPrivacidad: 'aceptaPrivacidad',
    }
};

function configurarLoginRegistro() {
    var body = $('body');
    var gestorMensajes = new Mensajes('[data-mensajes-error="registro-login"]')

    body.on('mostrar-formulario', function(event, data) {
        console.log("Abrir modal")
        console.log(data)
        gestorMensajes.limpiar();
        $('[data-formulario="login"]').hide();
        $('[data-formulario="registro"]').hide();
        $('[data-formulario="password-email"]').hide();
        $('[data-formulario="password-email-enviado"]').hide();
        $('[data-formulario="password"]').hide();
        $('[data-formulario="password-resetada"]').hide();
        $('[data-formulario="' + data.formulario + '"]').show();
    });
    var modales = new Modales();
    modales.abrirModalSeleccionada();


    var domFormularioModalLogin = $("#modal-formulario-login");

    if (domFormularioModalLogin.length) {

        var formularioModalLogin = new FormularioLogin();
        formularioModalLogin.configurar(domFormularioModalLogin, false, configuracionFormularioLogin, gestorMensajes);

        domFormularioModalLogin.on('submit', function(evento) {
            formularioModalLogin.eventoFormularioEnviado(evento)
        })
    }

    var domFormularioLogin = $("#login-form");

    if (domFormularioLogin.length) {
        var formularioLogin = new FormularioLogin();
        formularioLogin.configurar(domFormularioLogin, true, configuracionFormularioLogin, gestorMensajes);

        domFormularioLogin.on('submit', function(evento) {
            formularioLogin.eventoFormularioEnviado(evento)
        })
    }

    var domFormularioModalPasswordEmail = $("#modal-formulario-password-email");

    if (domFormularioModalPasswordEmail.length) {

        var formularioModalPasswordEmail = new FormularioPasswordEmail();
        formularioModalPasswordEmail.configurar(domFormularioModalLogin, false, configuracionFormularioLogin, gestorMensajes);

        domFormularioModalPasswordEmail.on('submit', function(evento) {
            formularioModalPasswordEmail.eventoFormularioEnviado(evento)
        })
    }

    var domFormularioModalPassword = $("#modal-formulario-password");

    if (domFormularioModalPassword.length) {

        var formularioModalPassword = new FormularioPasswordReset();
        formularioModalPassword.configurar(domFormularioModalPassword, false, configuracionFormularioLogin, gestorMensajes);

        domFormularioModalPassword.on('submit', function(evento) {
            formularioModalPassword.eventoFormularioEnviado(evento)
        })
    }

    var domFormularioRegistro = $("#mi-formulario-registro");

    if (domFormularioRegistro.length) {
        var validadorFormularioRegistro = new ValidadorRegistro();
        validadorFormularioRegistro.setup(domFormularioRegistro, configuracionFormularioRegistro, gestorMensajes);

        var formularioRegistro = new FormularioRegistro();
        formularioRegistro.configurar(domFormularioRegistro, configuracionFormularioRegistro.campos, validadorFormularioRegistro, gestorMensajes);

        domFormularioRegistro.on('submit', function(evento) {
            formularioRegistro.eventoFormularioEnviado(evento)
        })
    }

    var domFormularioModalRegistro = $("#modal-formulario-registro");

    if (domFormularioModalRegistro.length) {
        var validadorFormularioModalRegistro = new ValidadorRegistro();
        validadorFormularioModalRegistro.setup(domFormularioModalRegistro, configuracionFormularioRegistro, gestorMensajes);

        var formularioModalRegistro = new FormularioRegistro();
        formularioModalRegistro.configurar(domFormularioModalRegistro, configuracionFormularioRegistro.campos, validadorFormularioModalRegistro, gestorMensajes);

        domFormularioModalRegistro.on('submit', function(evento) {
            formularioModalRegistro.eventoFormularioEnviado(evento)
        })
    }



    $(document).on('click', '[data-click="cambiar-formulario"]', function (e) {
        e.preventDefault();
        var formularioAMostrar = $(e.target).data('nombre-formulario');

        $('[data-es-formulario="si"]').hide()
        gestorMensajes.limpiar();
        $('body').trigger('mostrar-formulario', { formulario: formularioAMostrar });
    })

    $(document).on('change', '[data-cambiado="dato-cambiado"]', function (e) {
        body.trigger('datos-cambiados');
    })

    $('[data-target="#login--modal"]').click(function(e) {
        console.log("Click")
        gestorMensajes.limpiar();
    })

    $('[name ="email"]').change(function (e) {
        e.preventDefault();
        var email = $(e.target).val();
        $('[name ="email"]').val(email);
    });

    $('[data-click="cambiar-email"]').click(function (e) {
        e.preventDefault();
        $('[name="email"]').val('');
        formularioLogin.ocultarPassword();
    })
    $('[data-campo="contrasena"]').click(function (e) {
        $('[data-mensaje-introduce-contrasena="si"]').hide();
    })

    body.on('mostrar-club-premium', function(event, data) {
        console.log('abrir club premium');
        console.log(data)
        gestorMensajes.limpiar();
        $('[data-formulario="login"]').hide();
        $('[data-formulario="registro"]').hide();
        $('[data-formulario="password-email"]').hide();
        $('[data-formulario="password-email-enviado"]').hide();
        $('[data-formulario="password"]').hide();
        $('[data-formulario="password-resetada"]').hide();
        $('[data-formulario="club-premium"]').show();
    });

    body.on('mostrar-felicidades', function(event, data) {
        console.log('abrir club premium');
        console.log(data)
        gestorMensajes.limpiar();
        $('[data-formulario="felicidades"]').show();
    });

    body.on('verificando-email', function() {
        $('[data-mensaje="verificando-email"]').show();
    });
    body.on('email-verificado', function() {
        $('[data-mensaje="verificando-email"]').hide();
    });
    body.on('verificando-usuario', function() {
        $('[data-mensaje="verificando-usuario"]').show();
    });
    body.on('usuario-verificado', function() {
        $('[data-mensaje="verificando-usuario"]').hide();
    });
    body.on('email-existe', function() {
        $('[data-mensaje-usuario="email-existe"]').show();
        $('[data-mensaje-usuario="email-no-existe"]').hide();
    });
    body.on('email-no-existe', function() {
        $('[data-mensaje-usuario="email-existe"]').hide();
        $('[data-mensaje-usuario="email-no-existe"]').show();
    });
    body.on('limpiar-mensajes', function() {
        gestorMensajes.limpiar();
        $('[data-mensaje="verificando-email"]').hide();
        $('[data-mensaje-usuario="email-existe"]').hide();
        $('[data-mensaje-usuario="email-no-existe"]').hide();
    })
    body.on('usuario-nuevo', function() {
        $('[data-mensaje="usuario-nuevo"]').show();
        $('#boton-enviar-formulario-registro').removeClass('disabled');
    });
    body.on('reactivar-botones', function() {
        $('#boton-enviar-formulario-registro').removeClass('disabled');
    });
    body.on('usuario-macheado', function() {
        $('[data-mensaje="usuario-nuevo"]').hide();
    });
    body.on('enviando-formulario', function() {
        //$('[data-tipo-boton="login-registro"]').attr('disabled', true);
        $('[data-mensaje="enviando-formulario"]').show();
        $('[data-mensaje="formulario-enviado"]').hide();
    });
    body.on('formulario-enviado', function() {
        //$('[data-tipo-boton="login-registro"]').attr('disabled', false);
        $('[data-mensaje="enviando-formulario"]').hide();
        $('[data-mensaje="formulario-enviado"]').show();
    })
    $('#boton-enviar-formulario-registro').on('change', function() {
        console.log("El botón ha cambiado");
    });

}

export default configurarLoginRegistro;
