import cargarToken from "./token";
import Email from "./email";
import esEmail from "./funciones";
import Fecha from "./fecha";
import Contrasena from "./contrasena";
import Macheo from "./macheo";
import Mensajes from "./mensajes";
import Telefono from "./telefono";
import Usuario from "./usuario";

function ValidadorRegistro() {
    this.formulario = null;
    this.inputFecha = null;
    this.contenedorErrores = null;
    this.camposObligatorios = [];
}

ValidadorRegistro.prototype.setup = function(domFormulario, configuracion, gestorMensajes) {
    this.domFormulario = domFormulario;
    if (configuracion.campoFecha) {
        this.inputFecha = this.domFormulario.find("#" + configuracion.campoFecha);
    }
    this.camposObligatorios = configuracion.camposObligatorios;
    this.errores = gestorMensajes;
}

ValidadorRegistro.prototype.validar = function() {
    this.errores.limpiar();

    var longitudArray = this.camposObligatorios.length;
    for (var i = 0; i < longitudArray; i++) {
        var campo = this.domFormulario.find('[name=' + this.camposObligatorios[i].nombre + ']');

        if (this.camposObligatorios[i].tipo === 'texto' || this.camposObligatorios[i].tipo === 'select') {
            if (!campo.val()) {
                this.errores.nuevo("Falta el campo: " + this.camposObligatorios[i].label + '.');
            }
        }
        if (this.camposObligatorios[i].tipo === 'email') {
            if (!campo.val()) {
                this.errores.nuevo("Falta el campo: " + this.camposObligatorios[i].label + '.');
            }
            if (!esEmail(campo.val())) {
                this.errores.nuevo("Email no válido: " + this.camposObligatorios[i].label + '.');
            }
        } else if (this.camposObligatorios[i].tipo === 'checkbox') {
            if (!campo.prop('checked')) {
                this.errores.nuevo("Debes marcar la casilla: " + this.camposObligatorios[i].label + '.');
            }
        } else if (this.camposObligatorios[i].tipo === 'file') {
            var extension = campo.val().split('.').pop().toLowerCase();
            if ($.inArray(extension, ['pdf', 'doc', 'docx']) === -1) {
                this.errores.nuevo("Has seleccionado una extensión no válida para el campo: " + this.camposObligatorios[i].label + '.');
            }
        }
    }

    this.validarCamposFecha();

    return this.errores;
}

ValidadorRegistro.prototype.validarCamposFecha = function() {
    if (!this.inputFecha) {
        return;
    }

    var dia = this.domFormulario.find('[name=dia]').val();
    var mes = this.domFormulario.find('[name=mes]').val();
    var agno = this.domFormulario.find('[name=agno]').val();

    this.inputFecha.val(agno + '-' + mes + '-' + dia);

    var fecha = new Fecha(dia, mes, agno);

    if (fecha.validar() === false) {
        this.errores.nuevo("La fecha introducida no es válida.");
    }
};

export default ValidadorRegistro;
