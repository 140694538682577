function Mensajes(elementoContenedorMensajes) {
    this.contenedor = $(elementoContenedorMensajes);
    this.mensajes = {
        ok: [],
        errores: []
    };
}
Mensajes.prototype.nuevo = function(mensaje) {
    this.mensajes.errores.push(mensaje);
}
Mensajes.prototype.limpiar = function() {
    this.mensajes.ok = [];
    this.mensajes.errores = [];
    this.mostrar();
}
Mensajes.prototype.hayErrores = function() {
    return this.mensajes.errores.length
}
Mensajes.prototype.mostrar = function() {
    var mensajesError = '';
    $.each(this.mensajes.errores, function(indice, error) {
        mensajesError += '<li>' + error + '</li>';
    });

    this.contenedor.html(mensajesError);
}

export default Mensajes;
