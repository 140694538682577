import FormularioPasswordEmail from "./formulario-password-email";
import funciones from './funciones';

function FormularioPasswordReset() {
    this.campos = {};
}
FormularioPasswordReset.prototype.configurar = function(domFormulario, tieneCampoOculto, configuracion, gestorMensajes) {
    this.password = domFormulario.find('[name ="password"]');
    this.token = funciones.getQueryStringParameterByName('token');
    this.tieneCampoOculto = tieneCampoOculto;
    this.tieneEmailValido = false;
    this.errores = gestorMensajes;

    this.body = $('body');

}
FormularioPasswordReset.prototype.eventoFormularioEnviado = function(evento) {

    evento.preventDefault();

    var datos = {
        _token: window.token,
        contrasena_token: this.token,
        password: this.password.val()
    };

    this.validarFormulario();

    if (this.errores.hayErrores()) {
        this.errores.mostrar();
        return;
    }

    this.body.trigger('enviando-formulario');

    $.post('/password/reset', datos)
        .done(function () {
            $('body').trigger('mostrar-formulario', { formulario: 'password-reseteada' });
            this.body.trigger('formulario-enviado');
        }.bind(this))
        .fail(function (error) {
            this.errores.nuevo(error.responseText);
            this.errores.mostrar();
            this.body.trigger('formulario-enviado');
        }.bind(this));
}

FormularioPasswordReset.prototype.validarFormulario = function(evento) {
    this.errores.limpiar();

    if (!this.password.val()) {
        this.errores.nuevo('Por favor, escribe una contraseña.');
        return false;
    }

    if (this.password.val().length<7) {
        this.errores.nuevo('Por favor, escribe una contraseña de, al menos, siete caracteres.');
        return false;
    }

}

export default FormularioPasswordReset;
