function setup() {
    document.addEventListener('click', function (event) {
        if (event.target.classList.contains('js-touchspin') || event.target.closest('.js-touchspin')) {
            deshabilitarBotones();
        }
    });

    document.addEventListener('change', function (event) {
        if (event.target.classList.contains('js-cart-line-product-quantity')) {
            deshabilitarBotones();
        }
    });
}

function deshabilitarBotones() {
    const botones = document.getElementsByClassName('js-touchspin');
    for (let i = 0; i < botones.length; i++) {
        botones[i].classList.add('disabled');
        botones[i].setAttribute('disabled', '');
    }
    const inputs = document.getElementsByClassName('js-cart-line-product-quantity');
    for (let i = 0; i < inputs.length; i++) {
        inputs[i].setAttribute('disabled', '');
    }
}

export default {
    setup,
};