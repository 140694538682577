function FormularioPasswordEmail() {
    this.campos = {};
}
FormularioPasswordEmail.prototype.configurar = function(domFormulario, tieneCampoOculto, configuracion, gestorMensajes) {
    this.email = domFormulario.find('[name ="email"]');
    this.tieneCampoOculto = tieneCampoOculto;
    this.tieneEmailValido = false;

    this.body = $('body');

}
FormularioPasswordEmail.prototype.eventoFormularioEnviado = function(evento) {

    evento.preventDefault();

    var datos = {
        _token: window.token,
        email: this.email.val()
    };

    this.body.trigger('enviando-formulario');

    $.post('/password/email', datos)
        .done(function () {
            $('[data-section="password-email"]').text(this.email.val());
            $('body').trigger('mostrar-formulario', { formulario: 'password-email-enviado' });
            this.body.trigger('formulario-enviado');
        }.bind(this));
}

export default FormularioPasswordEmail;
