function cargarToken() {
    return new Promise((resolve, reject) => {
        $.get('/apilocal/codigo')
            .done(respuesta => {
                $('#l-token-registro').val(respuesta)
                $('#l-token-login').val(respuesta)
                $('[data-token="web-estatica"]').val(respuesta);

                window.token = respuesta;

                resolve();
            });
    })

}

export default cargarToken;
