function Contrasena(contrasena) {
    this.longitudMinima = 6;
    this.contrasena = contrasena;
}
Contrasena.prototype.validar = function() {
    if (this.contrasena.length < this.longitudMinima) {
        this.error = 'La contraseña debe tener al menos ' + this.longitudMinima + ' caracteres';
        return false;
    }
    return true;
}
Contrasena.prototype.errorValidacion = function() {
    return this.error;
}

export default Contrasena;
