function esEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

/* Sacado de aquí: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript#5158301 */
function getQueryStringParameterByName(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export default { esEmail, getQueryStringParameterByName };
