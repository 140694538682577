function Fecha(dia, mes, agno) {
    this.dia = dia;
    this.mes = mes;
    this.agno = agno;

    this.meses = { 1: 31, 2: 28, 3: 31, 4: 30, 5: 31, 6: 30, 7: 31, 8: 31, 9: 30, 10: 31, 11: 30, 12: 31 };
}
Fecha.prototype.validar = function() {
    this.fechaValida = true;

    if (!this.dia || this.dia < 0 || this.dia > this.meses[this.mes]) {
        this.fechaValida = false;
    }
    if (!this.mes || this.mes < 0 || this.mes > 12) {
        this.fechaValida = false;
    }

    if (!this.agno) {
        this.fechaValida = false;
    }

    return this.fechaValida;
};

export default Fecha;
