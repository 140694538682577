import $ from 'jquery'
import 'slick-carousel'

function configurarSlider() {
    $('.top-products-slider').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 612,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    centerMode: true,
                    arrows: false
                }
            }]
    });

    $('.top-marcas-slider').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        // centerMode: true,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 612,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: true,
                    arrows: false
                }
            }]
    });
}

export default configurarSlider;
